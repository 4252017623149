import React, { useState } from 'react';

const MessageItem = props => {
  const [state, setState] = useState({
    editMode: false,
    editText: props.message.text,
  });

  const onToggleEditMode = () => {
    setState(state => ({
      ...state,
      editMode: !state.editMode,
      editText: props.message.text,
    }));
  };
  const onChangeEditText = event => {
    setState(state => ({
      ...state,
      editText: event.target.value 
    }));
    event.persist();
  };

  const onSaveEditText = () => {
    props.onEditMessage(props.message, state.editText);

    setState(state => ({
      ...state,
      editMode: false 
    }));
  };
  const { authUser, message, onRemoveMessage } = props;
  const { editMode, editText } = state;

  return (
    <li>
      {editMode ? (
        <input
          type="text"
          value={editText}
          onChange={onChangeEditText}
        />
      ) : (
        <span>
          <strong>{message.userId}</strong> {message.text}
          {message.editedAt && <span>(Edited)</span>}
        </span>
      )}

      {authUser.uid === message.userId && (
        <span>
          {editMode ? (
            <span>
              <button onClick={onSaveEditText}>Save</button>
              <button onClick={onToggleEditMode}>Reset</button>
            </span>
          ) : (
            <button onClick={onToggleEditMode}>Edit</button>
          )}

          {!editMode && (
            <button
              type="button"
              onClick={() => onRemoveMessage(message.uid)}
            >
              Delete
            </button>
          )}
        </span>
      )}
    </li>
  );
}

export default MessageItem;
