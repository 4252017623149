import React, { useState, useLayoutEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import MessageList from './MessageList';

const Messages = props => {
  const [state, setState] = useState({
    text: '',
    loading: true,
    messages: [],
    limit: 5,
    firebaseStarted: false,
    initialized: false,
  });

  useLayoutEffect(() => {
    const onListenForMessages = () => {
      setState(state => ({ ...state, loading: true }));
  
      try {
        const messagesRef = props.firebase.messages().orderByChild('createdAt').limitToLast(state.limit)
  
        messagesRef.on('value', snapshot => {
          const messageObject = snapshot.val();
  
          if (messageObject) {
            const messageList = Object.keys(messageObject).map(key => ({
              ...messageObject[key],
              uid: key,
            }));
            setState(state => ({
              ...state,
              messages: messageList,
              loading: false,
            }));
          } else {
            setState(state => ({ ...state, messages: null, loading: false }));
          }
        });
      } catch (error) {
        console.log('error', error);
      }
    };
    if (props.firebase && !state.firebaseStarted) {
      onListenForMessages();
    }
  }, [props.firebase, state.firebaseStarted, state.limit])

  const onChangeText = event => {
    event.persist();
    setState(state => ({ ...state, text: event.target.value }));
  };

  const onCreateMessage = (event, authUser) => {
    event.persist();
    props.firebase.messages().push({
      text: state.text,
      userId: authUser.uid,
      createdAt: props.firebase.serverValue.TIMESTAMP,
    })

    setState(state => ({ ...state, text: '' }));

    event.preventDefault();
  };

  const onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    props.firebase.message(message.uid).set({
      ...messageSnapshot,
      text,
      editedAt: props.firebase.serverValue.TIMESTAMP,
    });
  };

  const onRemoveMessage = uid => {
    props.firebase.message(uid).remove();
  };

  const onNextPage = () => {
    setState(
      state => ({ ...state, limit: state.limit + 5 }),
    );
  };

  const { text, messages, loading } = state;

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div>
          {!loading && messages && (
            <button type="button" onClick={onNextPage}>
              More
            </button>
          )}

          {loading && <CircularProgress />}

          {messages && (
            <MessageList
              authUser={authUser}
              messages={messages}
              onEditMessage={onEditMessage}
              onRemoveMessage={onRemoveMessage}
            />
          )}

          {!messages && <div>There are no messages ...</div>}

          <form
            onSubmit={event =>
              onCreateMessage(event, authUser)
            }
          >
            <input
              type="text"
              value={text}
              onChange={onChangeText}
            />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

export default withFirebase(Messages);
